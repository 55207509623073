var DateTimePickers = function() {

    // Anytime picker
    var _componentAnytime = function() {
        if (!$().AnyTime_picker) {
            console.warn('Warning - anytime.min.js is not loaded.');
            return;
        }

        // Basic usage
        $('.anytime-datetime').AnyTime_picker({
            format: '%Z-%m-%dT%H:%i:%s',
            firstDOW: 1
        });

        // Basic usage
        $('.anytime-date').AnyTime_picker({
            format: '%Z-%m-%d',
            firstDOW: 1
        });

    };

    return {
        init: function() {
               _componentAnytime();
        }
    }
}();

document.addEventListener('DOMContentLoaded', function() {
    DateTimePickers.init();
});